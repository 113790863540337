<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- page title -->
        <BaseTitleBar
          pageTitle="Receivables"
          itemsText="Record(s)"
          :itemArray="itemArray"
          :total-items="numOfReceiavbles"
        >
          <DropDownMenu btnText="New" icon="file" height="54px" justify="right">
            <template #titleButton>
              <v-list-item-group>
                <v-list>
                  <v-list-item
                    class="d-flex align-start"
                    v-for="(item, index) in receivableMenus"
                    :key="index"
                    @click="handleMenuAction(index)"
                  >
                    <v-list-item-content>
                      <v-list-item-title tag="button" slot="activator">
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-item-group>
            </template>
          </DropDownMenu>
        </BaseTitleBar>
        <!--  -->
        <!-- summary cards for receivables -->
        <div
          v-if="loadingReceivables"
          class="
            d-block d-md-flex
            justify-center
            align-center
            justify-md-space-around
            px-3 px-md-12
            pt-10
          "
        >
          <v-skeleton-loader
            class="mx-md-2 my-4"
            v-for="loader in 3"
            :key="loader"
            height="150px"
            width="100%"
            type="card"
          />
        </div>
        <div
          v-else
          class="
            container-wrapper
            d-sm-flex
            justify-center
            align-center
            py-6
            pb-md-0
            px-3
            pl-md-10
            pr-md-8
          "
        >
          <receivableCards
            class="ml-md-5 mr-md-3 mr-sm-3"
            :amount="
              receivablesBalances
                ? receivablesBalances.expected_payments
                : '' | currency(currencyDefault)
            "
            cardInfo="Expected payments"
          >
            <v-progress-linear
              rounded
              v-model="currentBillPercentage"
              color="#96EAD7"
              height="8"
              background-color="#FF6A6A"
            >
            </v-progress-linear>
            <div class="d-block">
              <p class="subTitle mb-0 pt-2 text-end">Due</p>
              <p class="subTitle__value text-end">
                {{
                  receivablesBalances
                    ? receivablesBalances.total_payments
                    : 0 | currency(currencyDefault)
                }}
              </p>
            </div>
          </receivableCards>
          <receivableCards
            class="mx-md-3 mx-sm-1"
            style="min-height: 150px"
            :amount="
              receivablesBalances ? receivablesBalances.customers.total : 0
            "
            cardInfo="Total Customers"
            :chart="true"
          >
            <pure-vue-chart
              :points="customersPerMonthData"
              :width="150"
              :height="50"
              bar-color="#96ead7"
              :show-x-axis="true"
            />
          </receivableCards>
          <receivableCards
            class="ml-md-3 mr-md-6 ml-sm-3"
            :amount="
              receivablesBalances ? receivablesBalances.debtors.total : 0
            "
            cardInfo="Total Debtors"
          >
            <v-progress-linear
              rounded
              v-model="currentDebtorPercentage"
              color="#19283D"
              height="8"
              background-color="#2BD5AE"
            >
            </v-progress-linear>
            <div class="d-block">
              <p class="subTitle mb-0 pt-2 text-end">last 30 days</p>
              <p class="subTitle__value text-end">
                {{
                  receivablesBalances
                    ? receivablesBalances.debtors.last30days
                    : 0
                }}
              </p>
            </div>
          </receivableCards>
        </div>
        <!-- summary cards for receivables ends here -->
        <!-- DataTable for Receivables starts here -->
        <v-container class="mt-md-12">
          <v-row class="mx-md-8 pa-0">
            <v-col
              class="d-flex flex-column align-center justify-center"
              cols="12"
            >
              <v-card
                v-if="$vuetify.breakpoint.mdAndUp"
                flat
                width="100%"
                style="border-bottom: 1px solid rgba(127, 145, 155, 0.3)"
              >
                <v-tabs v-model="tab">
                  <v-tab
                    class="mt-2 tab__text"
                    v-for="item in items"
                    :key="item.tab"
                    >{{ item.tab }}</v-tab
                  >

                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="isRefreshing"
                    class="mt-4"
                    color="primary"
                    small
                    text
                    @click="handleRefresh"
                    >Refresh <v-icon right>mdi-refresh</v-icon></v-btn
                  >

                  <v-btn
                    v-if="isClicked"
                    @click="toggleSearch"
                    plain
                    class="text-black mt-1 pt-4 searchBtn__text"
                  >
                    search
                    <v-icon small right class="pr-1"> mdi-magnify </v-icon>
                  </v-btn>
                  <v-expand-x-transition v-else>
                    <v-text-field
                      v-model="search"
                      @blur="isClicked = true && !search"
                      @input="searchDataTable"
                      class="seacrh-field mt-2 mr-2"
                      dense
                      clearable
                      autofocus
                      hide-details="true"
                      persistent-placeholder
                      placeholder="Search"
                      append-icon="mdi-magnify"
                      filled
                    >
                    </v-text-field>
                  </v-expand-x-transition>
                </v-tabs>
              </v-card>

              <v-card width="100%" class="pb-12" flat>
                <component
                  v-bind:is="items[tab].content"
                  class="ml-0"
                  :ref="items[tab].content"
                ></component>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!-- DataTable for Receivables ends here -->
      </v-col>
    </v-row>
    <!-- tabs for mobile devices -->
    <v-row
      class="px-0 mx-0"
      style="margin-top: 10vh"
      v-if="$vuetify.breakpoint.mdAndDown"
    >
      <v-col class="ma-0 pa-0" cols="12">
        <v-bottom-navigation fixed class="pa-0 mx-0" dark>
          <v-tabs
            center-active
            class="ma-0"
            background-color="primary"
            v-model="tab"
          >
            <v-tab
              class="mt-2"
              v-for="item in items"
              :key="item.tab"
              style="
                font-family: Inter;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                text-transform: uppercase;
              "
              >{{ item.tab }}</v-tab
            >
          </v-tabs>
        </v-bottom-navigation>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseTitleBar from "../../components/BaseTitleBar.vue";
import DropDownMenu from "@/includes/DropDownMenu.vue";
import CardAvatar from "../../components/CardAvatar.vue";
import SingleSummaryCard from "../../components/SingleSummaryCard.vue";
import PureVueChart from "pure-vue-chart";
import AllReceivables from "@/components/receivableTabs/AllReceivables.vue";
import PaidReceivables from "../../components/receivableTabs/PaidReceivables.vue";
import { mapGetters } from "vuex";
import ReceivableCards from "./components/receivableCards.vue";
import currencyFilter from "@/mixins/currencyFilter";

export default {
  name: "Receivables",
  data() {
    return {
      isRefreshing: false,
      tab: 0,
      search: "",
      isClicked: true,
      itemArray: [1, 2],
      totalItems: 265,
      loadingReceivables: false,
      avatarsize: 80,
      totalInvoiceValue: 234560,
      customersPerMonth: [1, 3, 2, 3, 4, 2],

      items: [
        { tab: "All", content: "AllReceivables" },
        { tab: "Paid", content: "PaidReceivables" },
      ],
      receivableMenus: [
        {
          title: "Request Payment",
        },
        {
          title: "Request Bulk",
        },
      ],
    };
  },
  mixins: [currencyFilter],
  components: {
    BaseTitleBar,
    SingleSummaryCard,
    CardAvatar,
    PureVueChart,
    AllReceivables,
    PaidReceivables,
    ReceivableCards,
    DropDownMenu,
  },
  computed: {
    //getter
    ...mapGetters({
      organizationToken: "organizations/OrganToken",
      receivablesBalances: "receivables/getReceivablesBalance",
      numOfReceiavbles: "receivables/getReceivablesCount",
    }),

    // calculate the percentage of expected payment to payment due
    currentBillPercentage() {
      return (
        (this.receivablesBalances?.expectedPayment /
          this.receivablesBalances?.paymentDue) *
        100
      );
    },

    // calculate the percentage of total debtors to number of debtors for last 30 days
    currentDebtorPercentage() {
      return (
        (this.receivablesBalances?.debtors.total /
          this.receivablesBalances?.debtors.last30days) *
        100
      );
    },

    // get the number of customers per month for the last 6 months and return it as an array
    // customersPerMonth() {
    //   let customersPerMonth = [];
    //   for (let i = 0; i < 6; i++) {
    //     customersPerMonth.push(
    //       this.receivablesBalances.customersPerMonth[
    //         this.receivablesBalances.customersPerMonth.length - 1 - i
    //       ]
    //     );
    //   }
    //   return customersPerMonth;
    // },

    //return last 6 months short name from current month in a reversed order in an array
    months() {
      let lastSixMonths = [];
      let month = new Date().getMonth();
      for (let i = 0; i < 6; i++) {
        lastSixMonths.push(
          new Date(0, month - i, 1).toLocaleString("default", {
            month: "short",
          })
        );
      }
      return lastSixMonths.reverse();
    },

    //use the months array to get a key value pair of month and number of customers
    customersPerMonthData() {
      let customersPerMonthData = [];
      for (let i = 0; i < this.months.length; i++) {
        customersPerMonthData.push({
          label: this.months[i],
          value: this.customersPerMonth[i],
        });
      }
      return customersPerMonthData;
    },
  },
  methods: {
    toggleSearch() {
      this.isClicked = false;
    },
    searchDataTable(e) {
      this.$refs[this.items[this.tab].content].setSearchText(e);
      //  console.log(e);
    },

    //vuex action to get all receivables
    async getAllReceivables() {
      const organHypnId = await this.organizationToken.data?.hypn_id;
      if (organHypnId) {
        try {
          this.$store.dispatch("receivables/getAllReceivables", organHypnId);
        } catch (error) {
          console.log(error);
        }
      }
    },

    // vuex action to get receivable balance
    async getReceivablesBalance() {
      const organHypnId = await this.organizationToken.data?.hypn_id;
      try {
        this.loadingReceivables = true;
        if (organHypnId != null) {
          this.$store.dispatch(
            "receivables/getReceivablesBalance",
            organHypnId
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingReceivables = false;
      }
    },

    handleMenuAction(index) {
      console.log(index);
      if (index === 0) {
        // open Make customer Invoice page
        this.$router.push({ name: "NewInvoice" });
      } else if (index === 1) {
        this.$router.push({ name: "RequestPayment" });
      }
    },
    // handle refresh data tables
    handleRefresh() {
      this.isRefreshing = true; // Set refresh flag to true
      //  Call the child component method to refresh the data
      this.$refs[this.items[this.tab].content].handleRefreshed();
      // Simulate async refresh process
      setTimeout(() => {
        this.isRefreshing = false; // Reset refresh flag after refresh is complete
      }, 2000); // Adjust the timeout duration as per your requirement
    },
  },
  mounted() {
    if (this.organizationToken != null) {
      // call the vuex action to get receivables balance
      this.getReceivablesBalance();
      //call the get all receivables action
      this.getAllReceivables();
    }
  },
  // watch: {
  //   //watch the organizationToken value and if its not null call the getAllReceivables method
  //   organizationToken: {
  //     deep: true,
  //     immediate: true,
  //     handler(val) {
  //       if (val != null) {
  //         this.$store.dispatch(
  //           "receivables/getAllReceivables",
  //           val.data?.hypn_id
  //         );

  //         // dispatch receivable balance action
  //         // this.$store.dispatch(
  //         //   "receivables/getReceivablesBalance",
  //         //   val.data?.hypn_id
  //         // );
  //       }
  //     },
  //   },
  // },
};
</script>

<style scoped>
.subTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.545455px;
  color: #525e6e;
}

.subTitle__value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.545455px;
  color: #525e6e;
}

.card__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: rgba(25, 40, 61, 0.8);
}

.total__customers {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #19283d;
}

.tab__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}

.searchBtn__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.55px;
  text-transform: uppercase;
  color: #7f919b;
}
</style>
