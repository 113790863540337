<template>
  <div class="detail-drawer" v-if="singleReceivable">
    <div class="d-flex justify-space-between align-center">
      <p class="title pb-3">Receivable Detail</p>
      <v-btn @click="close" icon color="primary">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="section__div">
      <p class="headerText">customer</p>
      <p class="valueText linkText" @click.prevent="openSingleCustomer">
        {{ customerEmail }}
      </p>
    </div>
    <div class="section__div">
      <p class="headerText">Amount</p>
      <p class="amountText">
        {{
          singleReceivable.total
            | currency({ symbol: singleReceivable.currency })
        }}
      </p>
    </div>
    <div class="section__div">
      <p class="headerText">Due Date</p>
      <p class="valueText">{{ singleReceivable.invoice.due_date }}</p>
    </div>
    <div class="section__div">
      <p class="headerText">Paid</p>
      <p class="valueText">
        {{
          singleReceivable.paid
            | currency({ symbol: singleReceivable.currency })
        }}
      </p>
    </div>
    <div class="section__div">
      <p class="headerText">Expected Payment</p>
      <p class="valueText">
        {{ expectedPayment | currency({ symbol: singleReceivable.currency }) }}
      </p>
    </div>
    <div class="section__div">
      <p class="headerText">Invoice Ref.</p>
      <p class="valueText">{{ singleReceivable.invoice.invoicenumber }}</p>
    </div>
    <!-- <div class="section__div">
      <p class="headerText pb-2">Category</p>
      <v-select outlined hide-details="auto"></v-select>
    </div> -->
    <div class="section__div">
      <p class="headerText pt-5 pb-4">Payment Timeline</p>
      <div class="content">
        <div class="card py-10" v-if="singleReceivable.timeline.length === 0">
          <p class="card__value pb-0">
            No payment received for this transaction
          </p>
        </div>
        <div v-for="(card, i) in singleReceivable.timeline" :key="i" v-else>
          <div class="card">
            <p class="card__value">
              {{
                card.amount | currency({ symbol: singleReceivable.currency })
              }}
            </p>
            <p class="card__text"><b>Source: </b>{{ card.counterparty }}</p>
            <p class="card__text"><b>Date: </b>{{ card.date | moment("l") }}</p>
            <!-- <p class="card__text"><b>Fee: </b>{{ card.fee }}</p> -->
            <p class="card__text text-truncate">
              <b>Narration: </b>
              <br />
              <span>{{ card.narration }}</span>
            </p>
          </div>
        </div>

        <div v-if="expectedPayment > 0">
          <div class="d-flex align-center">
            <v-hover v-slot="{ hover }">
              <v-btn
                @click="displayAddPaymentForm"
                :style="{
                  'background-color': hover ? '#2bd5ae' : '',
                  border: hover ? 'none' : '',
                }"
                exact-path
                depressed
                outlined
                dark
                small
                color="#2BD5AE"
                class="text-capitalize px-3 rounded"
                style="color: var(--v-primary-base)"
              >
                Add Payment
              </v-btn>
            </v-hover>
          </div>
          <div class="d-flex align-center py-2">
            <p class="infoText">
              Manually add a payment record for this receivable. NOTE: this
              cannot be reversed
            </p>
          </div>
          <span v-if="showAddPaymentForm">
            <v-form ref="postAddPaymentForm">
              <div class="py-1">
                <label>Select an Account</label>
                <v-select
                  dense
                  :items="filteredAccountArray"
                  item-value="hypn_id"
                  item-text="account"
                  :menu-props="{ searchable: true, lazy: true }"
                  prepend-inner-icon="mdi-magnify"
                  hide-details="auto"
                  outlined
                  v-model="addPaymentObject.bank"
                  class="hypn-input"
                  :rules="[(value) => !!value || 'select an option']"
                >
                  <template #item="{ item }">
                    <div class="item-wrapper py-1">
                      <span>{{ item.account }}</span>
                      <small class="grey--text text--lighten-1">{{
                        item.bank
                      }}</small>
                    </div>
                  </template>
                </v-select>
                <p class="txt_btn" @click="handleAddBankModal">
                  + add Bank Account
                </p>
              </div>
              <div class="py-1">
                <label>Exact Amount</label>
                <v-text-field
                  dense
                  v-model="addPaymentObject.amount"
                  hide-details="auto"
                  outlined
                  placeholder="0.00"
                  @blur="formatAmount"
                  :rules="[
                    (value) => !!value || 'Amount is required',
                    (value) =>
                      /^(\d{1,3}(,\d{3})*|\d+)(\.\d{1,2})?$/.test(value) ||
                      'Enter a valid amount',
                    (value) =>
                      parseFloat(value) <= this.expectedPayment ||
                      `Amount should not exceed ${expectedPayment} expected amount`,
                    (value) => parseFloat(value) > 0 || 'Amount can not be 0',
                  ]"
                >
                </v-text-field>
              </div>
              <div class="py-1">
                <label>Narration</label>
                <v-text-field
                  dense
                  v-model="addPaymentObject.narration"
                  hide-details="auto"
                  outlined
                  class="hypn-input"
                  :rules="[(value) => !!value || 'Narration is required']"
                >
                </v-text-field>
              </div>
              <v-row>
                <v-col cols="6" class="px-0 ml-3">
                  <v-menu
                    ref="transMenu"
                    v-model="transMenu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    bottom
                    offset-overflow
                    min-width="auto"
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        background-color="white"
                        v-model="addPaymentObject.date"
                        hide-details="auto"
                        :rules="[(v) => !!v || 'Value is required']"
                        v-bind="attrs"
                        outlined
                        v-on="on"
                        class="text__input px-0 hypn-input"
                        persistent-hint
                        style="margin-top: 16px"
                      >
                        <template>
                          <v-icon
                            small
                            class="input__icon ml-0"
                            slot="prepend-inner"
                            color="grey lighten-1"
                          >
                            mdi-calendar-month-outline
                          </v-icon>
                        </template>
                        <template #label> Trnx Date </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="addPaymentObject.date"
                      :hide-details="true"
                      outlined
                      no-title
                      @input="transMenu = false"
                      color="primary"
                      :max="getTodayDate()"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <div class="py-1">
                <label>Tax Deduction?</label>
                <v-select
                  dense
                  :items="taxdeductionOptions"
                  item-value="value"
                  item-text="text"
                  v-model="taxdeduction"
                  hide-details="auto"
                  outlined
                  :rules="[(value) => !!value || 'select an option']"
                >
                </v-select>
              </div>
              <div v-if="taxdeduction === 'yes'">
                <label>Deduction Amount</label>
                <v-text-field
                  dense
                  v-model="addPaymentObject.deduction"
                  hide-details="auto"
                  placeholder="0.00"
                  outlined
                  class="hypn-input"
                  @blur="formatDeductedAmount"
                  :rules="[
                    (value) => !!value || 'Amount is required',
                    (value) =>
                      /^(\d{1,3}(,\d{3})*|\d+)(\.\d{1,2})?$/.test(value) ||
                      'Enter a valid amount',
                    (value) => parseFloat(value) > 0 || 'Amount can not be 0',
                  ]"
                >
                </v-text-field>
              </div>
            </v-form>
            <div class="py-5">
              <v-btn
                :loading="sendingPayment"
                :color="disablePost ? '#d9dee1' : 'primary'"
                class="text-capitalize py-md-6 px-md-8"
                :disabled="disablePost"
                :style="
                  disablePost
                    ? ''
                    : 'box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24)'
                "
                @click="handlePostTransaction"
              >
                <v-icon> mdi-chevron-right </v-icon>
                <span class="ml-2">Add Payment</span>
              </v-btn>
            </div>
            <AddBankModal ref="AddBankDialog" @clicked="launchMono" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import currencyFilter from "@/mixins/currencyFilter.js";
import { mapGetters, mapActions } from "vuex";
// Import the utility function
import { formatCurrencyAmount } from "@/mixins/amountFormatter.js";
import AddBankModal from "@/components/addBankModal.vue";
export default {
  name: "ReceivableDetails",
  components: { AddBankModal },
  props: {
    receivableId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      taxdeductionOptions: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
      ],
      taxdeduction: null,
      transMenu: false,
      addPaymentObject: {
        direction: "credit",
        type: "transfer",
        narration: "",
        bank: "",
        entity: "",
        entity_type: "customer",
        to: "",
        amount: (0).toFixed(2),
        date: null,
        deduction: (0).toFixed(2),
      },
      showAddPaymentForm: false,
      disablePost: false,
      sendingPayment: false,
    };
  },
  mixins: [currencyFilter],
  methods: {
    // mapActions
    ...mapActions({ showToast: "ui/showToast" }),

    // close drawer
    close() {
      this.$emit("close");
    },

    // open single customer
    openSingleCustomer() {
      this.$router.push({
        name: "contact.customer",
        params: { id: this.customerId },
      });
    },

    formatAmount() {
      this.addPaymentObject.amount = formatCurrencyAmount(
        this.addPaymentObject.amount
      );
    },
    formatDeductedAmount() {
      this.addPaymentObject.deduction = formatCurrencyAmount(
        this.addPaymentObject.deduction
      );
    },

    // set max date

    getTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    async handlePostTransaction() {
      if (this.$refs.postAddPaymentForm.validate()) {
        // Check if this.addPaymentPayload.amount is a string
        if (typeof this.addPaymentPayload.amount === "string") {
          // Remove commas and periods from the amount string
          const floatAmount = this.addPaymentPayload.amount.replace(/,/g, "");
          const floatAmountDeducted = this.addPaymentPayload.deduction.replace(
            /,/g,
            ""
          );

          // Convert the cleaned amount string to an integer
          this.addPaymentPayload.amount = parseFloat(floatAmount);
          this.addPaymentPayload.deduction = parseFloat(floatAmountDeducted);

          const postData = {
            organizationId: this.organizationId,
            payload: this.addPaymentPayload,
          };

          try {
            // Set the sendingPayment state to true`
            this.sendingPayment = true;
            await this.$store.dispatch(
              "transactions/postABankTransactions",
              postData
            );

            await this.$store.dispatch(
              "receivables/getAllReceivables",
              this.$orgHypnId
            );

            this.close();

            // Display a success toast message if the dispatch is successful
            this.showToast({
              sclass: "success",
              show: true,
              message: "Transaction posted successfully!",
              timeout: 3000,
            });

            // disable the post btn
            this.disablePost = true;
          } catch (error) {
            this.showToast({
              sclass: "error",
              show: true,
              message: error.msg,
              timeout: 3000,
            });
          } finally {
            // Set the sendingPayment state to false`
            this.sendingPayment = false;
          }
        } else {
          // Handle the case where this.addPaymentPayload.amount is not a string
          console.error("Amount is not a string.");
        }
      }
    },

    // open the add a bank modal
    async handleAddBankModal() {
      await this.$refs.AddBankDialog.fetchAllBanks();
      this.$refs.AddBankDialog.show(true);
    },

    //method to launch the Mono app
    launchMono() {
      const options = {
        onSuccess: (response) => {
          this.submitBankCode(response);
          this.$store.dispatch("organizations/fetchBanks");
        },
        onClose: function () {
          console.log("user closed the widget.");
        },
      };
      this.$launchMono(options);
    },

    // toggle showAddPaymentForm
    displayAddPaymentForm() {
      if (!this.showAddPaymentForm) {
        // Format the expectedAmount to return a value in the format of 2,000.00
        const formattedAmount = this.expectedPayment.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        // Assign the formatted amount to this.addPaymentObject.amount
        this.addPaymentObject.amount = formattedAmount;
      }

      this.showAddPaymentForm = !this.showAddPaymentForm;
    },
  },
  computed: {
    //vuex getters
    ...mapGetters({
      receivables: "receivables/getAllReceivables",
      organizationToken: "organizations/OrganToken",
      singleReceivable: "receivables/getReceivables",
      organizationId: "auth/organizationId",
      filteredAccountArray: "organizations/getAccountObject",
    }),

    // calculate expected payment  from singleReceivable.total and singleReceivable.paid
    expectedPayment() {
      return this.singleReceivable.total - this.singleReceivable.paid;
    },

    // filter receivables by receivableId to get customer email
    customerEmail() {
      return this.receivables.filter(
        (receivable) => receivable?.id === this.receivableId
      )[0]?.customer?.email;
    },

    // filter receivables by receivableId to get customer id
    customerId() {
      return this.receivables.filter(
        (receivable) => receivable.id === this.receivableId
      )[0].customer?.id;
    },

    // get payload to add payment
    addPaymentPayload() {
      return {
        direction: this.addPaymentObject.direction,
        type: this.addPaymentObject.type,
        narration: this.addPaymentObject.narration,
        bank: this.addPaymentObject.bank,
        entity_type: this.addPaymentObject.entity_type,
        entity: this.singleReceivable.customer.hypn_id,
        to: "",
        amount: this.addPaymentObject.amount,
        tranx: this.singleReceivable.hypn_id,
        date: this.addPaymentObject.date,
        deduction: this.addPaymentObject.deduction,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}

label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(25, 40, 61, 0.8);
}

.hypn-input >>> ::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 15px;
  color: #8f96a1;
}

.input__icon {
  line-height: 24px;
  padding-right: 1px;
  padding-left: 2px;
}

.v-text-field ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

.v-select ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

.detail-drawer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px 40px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 10px;
  }

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #19283d;
    display: block;
  }

  .section__div {
    padding-bottom: 10px;

    .headerText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #8f96a1;
      display: block;
    }

    .valueText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 28px;
      color: #19283d;
      display: block;
    }

    .amountText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #19283d;
      display: block;
    }
  }
}

.section__div {
  padding-bottom: 10px;

  .headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #8f96a1;
    display: block;
  }

  .valueText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 28px;
    color: #19283d;
    display: block;
  }

  .amountText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #19283d;
    display: block;
  }

  .linkText {
    cursor: pointer;
  }
}

.txt_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #19283d;
  cursor: pointer;
}

.content::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

.content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.content::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-base);
  border-radius: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  // display: grid;
  // grid-template-columns: repeat(2, minmax(0, 1fr));
  // flex: 1;
  // overflow: auto;
  // margin-top: 5px;
  // &__action {
  //   padding: 0px 5px;
  //   cursor: pointer;
  //   margin: 20px 0px;
  //   &:hover {
  //     span {
  //       color: #d7a47b;
  //     }
  //   }
  //   img {
  //     width: 30px;
  //     height: 30px;
  //     display: block;
  //     margin: auto;
  //   }
  //   span {
  //     display: block;
  //     text-align: center;
  //     margin-top: 10px;
  //     color: #7f919b;
  //   }
  //   .coming-soon {
  //     font-family: "Inter";
  //     opacity: 0.9;
  //     margin-top: 1px;
  //     color: #d7a47b;
  //   }
  // }

  .card {
    background: #f8f9fc;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 10px 20px;

    &__value {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 28px;
      color: #19283d;
    }

    &__text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 28px;
      color: #8f96a1;
      opacity: 0.8;
    }
  }
}

.infoText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: rgba(25, 40, 61, 0.8);
}
</style>
