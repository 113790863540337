<template>
  <div>
    <div>
      <v-card
        height="60px"
        width="100%"
        flat
        class="d-flex"
        style="background: rgba(127, 145, 155, 0.052607)"
      >
        <!-- <v-switch
            flat
            dense
            class="pl-4 mb-2"
            color="#16be98"
            v-model="autoPay"
            label="Auto pay"
          ></v-switch> -->
      </v-card>
    </div>
    <v-skeleton-loader type="table" :loading="isLoading">
      <v-layout
        row
        wrap
        class="align-center pb-4 pt-6 pl-10 pr-8 text-body-2"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-flex md1>
          <div class="d-flex align-center justify-center">
            <p class="mb-0 primary--text font-weight-bold text-center">#</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Customer</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Currency</p>
          </div>
        </v-flex>

        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Amount</p>
          </div>
        </v-flex>
        <!-- <v-flex md1>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Bank</p>
          </div>
        </v-flex> -->
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Ref</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Paid</p>
          </div>
        </v-flex>

        <!-- <v-flex md2>
          <div class="d-flex align-center pl-2">
            <p class="mb-0 primary--text font-weight-bold">Tag</p>
          </div>
        </v-flex> -->

        <v-flex md2>
          <div class="d-flex justify-center align-center">
            <p class="mb-0 primary--text font-weight-bold">Created</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold pr-2">Actions</p>
          </div>
        </v-flex>
      </v-layout>

      <v-row v-if="filteredReceivables && filteredReceivables.length > 0">
        <v-col
          cols="12"
          v-for="receivable in displayedItems"
          :key="receivable.index"
          class="py-0 ma-0"
        >
          <ReceivableTable
            v-if="$vuetify.breakpoint.mdAndUp"
            :index="receivable.index"
            :id="receivable.id"
            :receivableRef="
              receivable.invoice ? 'receivable.invoice.invoicenumber' : 'N/A'
            "
            :bank="receivable.bank"
            :currency="
              receivable.currency == 'NGN'
                ? '₦'
                : receivable.currency == 'USD'
                ? '$'
                : receivable.currency == 'EUR'
                ? '€'
                : receivable.currency == 'GBP'
                ? '£'
                : receivable.currency == null
                ? '₦'
                : receivable.currency
            "
            :customer="receivable.customer.email"
            :tag="receivable.tag"
            :date="receivable.created_at | moment('DD/MM/YYYY')"
            :amount="receivable.total | currency(currencyformat)"
            :status="receivable.status"
            @openDetails="openDetails"
          />
          <!-- Data table for mobile -->
          <ReceivablesTableCard
            v-if="$vuetify.breakpoint.smAndDown"
            :index="i + 1"
            :id="receivable.id"
            :receivableRef="
              receivable.invoice ? 'receivable.invoice.invoicenumber' : 'N/A'
            "
            :bank="receivable.bank"
            :currency="
              receivable.currency == 'NGN'
                ? '₦'
                : receivable.currency == 'USD'
                ? '$'
                : receivable.currency == 'EUR'
                ? '€'
                : receivable.currency == 'GBP'
                ? '£'
                : receivable.currency == null
                ? '₦'
                : receivable.currency
            "
            :customer="receivable.customer.email"
            :tag="receivable.tag"
            :date="receivable.created_at | moment('DD/MM/YYYY')"
            :amount="receivable.total | currency(currencyformat)"
            :status="receivable.status"
            @openDetails="openDetails"
          />
        </v-col>
      </v-row>

      <div class="d-flex justify-center align-center pt-6" v-else>
        <span class="text--primary text-h5 font-monospace"
          >No Data available</span
        >
      </div>
    </v-skeleton-loader>
    <!-- pagination component -->
    <div
      class="pagination__container px-8"
      v-if="filteredReceivables && filteredReceivables.length > 0"
    >
      <BasePagination
        :current-page="currentPage"
        :visible-pages="visiblePageValue"
        :total-pages="totalPages"
        @page-changed="handlePageChange"
      />
    </div>
    <!-- right sided vuetify navigation drawer -->
    <div>
      <v-navigation-drawer
        width="360px"
        temporary
        right
        v-model="detailsDrawer"
        :hide-overlay="true"
        app
        style="box-shadow: 0px 4px 16px rgba(192, 196, 202, 0.15)"
      >
        <div
          class="d-flex justify-center align-center"
          style="height: 100vh; width: 100%"
          v-if="loadingDetails"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <ReceivableDetails
          @close="detailsDrawer = false"
          :receivableId="recId"
          v-else
        />
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReceivableDetails from "./receivableDetails.vue";
import ReceivablesTableCard from "./ReceivablesTableCard.vue";
import ReceivableTable from "./ReceivableTable.vue";
import BasePagination from "@/components/BasePagination.vue";
import pagination from "@/mixins/pagination.js";
export default {
  components: {
    ReceivableTable,
    ReceivablesTableCard,
    ReceivableDetails,
    BasePagination,
  },
  mixins: [pagination],
  data() {
    return {
      searchQuery: "",
      itemsPerPage: 10,
      autoPay: false,

      dragging: false,
      dialog: false,
      currencyformat: {
        symbol: "",
        thousandsSeparator: ",",
        fractionCount: "",
        fractionSeparator: "",
        symbolPosition: "",
        symbolSpacing: "",
        avoidEmptyDecimals: undefined,
      },
      detailsDrawer: false,
      isLoading: false,
      loadingDetails: false,
      recId: "",
    };
  },
  methods: {
    closeModal() {
      this.dialog = false;
      // this.workflowName = e;
      // console.log(e);
    },
    onDroppedFiles($event) {
      this.dragging = false;
      let files = [...$event.dataTransfer.items]
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile());

      console.table(files);
    },
    setSearchText(value) {
      this.searchQuery = value;
    },

    // Method to handle the refreshed event
    handleRefreshed() {
      this.loading = true;
      this.getAllReceivables();
    },

    //vuex action to get all receivables
    async getAllReceivables() {
      const organHypnId = await this.organizationToken.data?.hypn_id;
      if (organHypnId) {
        try {
          await this.$store.dispatch(
            "receivables/getAllReceivables",
            organHypnId
          );
        } catch (error) {
          console.log(error);
        }
      }
    },

    // open details drawer
    async openDetails(receivableID) {
      this.detailsDrawer = true;
      this.loadingDetails = true;
      await this.$store.dispatch("receivables/getReceivable", receivableID);
      this.fetchAllBankAccounts();
      this.recId = receivableID;
      this.loadingDetails = false;
    },
    async fetchAllBankAccounts() {
      let organizationId = await this.organizationId;

      await this.$store.dispatch(
        "organizations/getOrgBankAccounts",
        organizationId
      );
      // await this.$store.dispatch("organizations/fetchBanks");
    },
  },

  computed: {
    //vuex getters
    ...mapGetters({
      receivables: "receivables/getAllReceivables",
      organizationToken: "organizations/OrganToken",
      singleReceivable: "receivables/getReceivables",
      organizationId: "auth/organizationId",
    }),

    filteredReceivables() {
      // Optional chaining to safely access and manipulate searchQuery
      const searchQuery = this.searchQuery?.trim().toLowerCase();
      if (!searchQuery) {
        // If searchQuery is empty or undefined, return all receivables
        return this.receivables;
      }

      // Filter receivables based on searchQuery
      return this.receivables.filter((receivable) => {
        // Helper function to check if a property contains the searchQuery
        const matchProperty = (property) =>
          (property || "").toLowerCase().includes(searchQuery);

        // Accessing properties of the receivable object with optional chaining
        const customerEmail = receivable?.customer?.email;
        const invoiceNumber = receivable?.invoice?.invoicenumber;
        const tag = receivable?.tag;
        const bank = receivable?.bank;
        const status = receivable?.status;
        const total = receivable?.total?.toString();

        // Check if any property matches the searchQuery
        return (
          matchProperty(customerEmail) ||
          matchProperty(invoiceNumber) ||
          matchProperty(tag) ||
          matchProperty(bank) ||
          matchProperty(status) ||
          matchProperty(total)
        );
      });
    },

    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredReceivables.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (
        this.filteredReceivables &&
        this.filteredReceivables.length > this.itemsPerPage
      ) {
        let arraySubset = this.filteredReceivables?.slice(startIndex, endIndex);
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      } else {
        let arraySubset = this.filteredReceivables?.slice();
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      }
    },
    totalPages() {
      return Math.ceil(this.filteredReceivables.length / this.itemsPerPage);
    },
  },
  mounted() {
    // is loading is set to true if !receivables using setTimeout
    if (!this.receivables) {
      this.isLoading = true;
      setTimeout(
        () => {
          this.isLoading = false;
        },
        3000,
        this.receivables
      );
    }
  },
};
</script>

<style scoped>
.switch-card {
  height: 53px;
  background: rgba(127, 145, 155, 0.052607);
}

.switch {
  width: 15.28px;
  height: 15.28px;
}

.export-btn {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;

  color: #19283d;
}

i.sli-font {
  font-size: 12px !important;
  display: inline-block !important;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 6px;
}
</style>
