// currencyUtils.js

// Utility function to format a currency amount
export function formatCurrencyAmount(amount) {
  // Remove leading zeros if the amount starts with '0'
  while (amount.charAt(0) === "0" && amount.length > 1) {
    amount = amount.substring(1); // Remove the first character
  }

  // Convert to number and handle NaN or non-numeric inputs
  const numericAmount = parseFloat(amount);
  if (isNaN(numericAmount)) {
    return "";
  }

  // Convert to string and ensure at most two decimal places
  const formattedAmount = numericAmount.toFixed(2);

  // Insert commas as thousand separators
  return formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
