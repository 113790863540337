<template>
  <div>
    <div>
      <v-card
        height="60px"
        width="100%"
        flat
        class="d-flex"
        style="background: rgba(127, 145, 155, 0.052607)"
      >
      </v-card>
    </div>
    <v-layout
      row
      wrap
      class="align-center pb-4 pt-6 pl-10 pr-8 text-body-2"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-flex md1>
        <div class="d-flex align-center justify-center">
          <p class="mb-0 primary--text font-weight-bold text-center">#</p>
        </div>
      </v-flex>
      <v-flex md2>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Customer</p>
        </div>
      </v-flex>
      <v-flex md1>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Currency</p>
        </div>
      </v-flex>

      <v-flex md2>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Amount</p>
        </div>
      </v-flex>
      <!-- <v-flex md1>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Bank</p>
        </div>
      </v-flex> -->
      <v-flex md2>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Ref</p>
        </div>
      </v-flex>
      <v-flex md1>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Paid</p>
        </div>
      </v-flex>

      <!-- <v-flex md2>
        <div class="d-flex align-center pl-2">
          <p class="mb-0 primary--text font-weight-bold">Tag</p>
        </div>
      </v-flex> -->

      <v-flex md2>
        <div class="d-flex justify-center align-center">
          <p class="mb-0 primary--text font-weight-bold">Date</p>
        </div>
      </v-flex>
      <v-flex md1>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold pr-2">Actions</p>
        </div>
      </v-flex>
    </v-layout>

    <v-row v-if="paidReceivables && paidReceivables.length > 0">
      <v-col
        cols="12"
        v-for="(receivable, i) in displayedItems"
        :key="i"
        class="py-0 ma-0"
      >
        <ReceivableTable
          v-if="$vuetify.breakpoint.mdAndUp"
          :index="i + 1"
          :id="receivable.id"
          :receivableRef="receivable.reference"
          :bank="receivable.bank"
          :currency="
            receivable.currency == 'NGN'
              ? '₦'
              : receivable.currency == 'USD'
              ? '$'
              : receivable.currency == 'EUR'
              ? '€'
              : receivable.currency == 'GBP'
              ? '£'
              : receivable.currency == null
              ? '₦'
              : receivable.currency
          "
          :customer="receivable.customer.email"
          :tag="receivable.tag"
          :date="receivable.created_at | date"
          :amount="receivable.total | currency(currencyformat)"
          :status="receivable.status"
          @openDetails="openDetails"
        />

        <!-- Data table for mobile -->
        <ReceivablesTableCard
          v-if="$vuetify.breakpoint.smAndDown"
          :index="i + 1"
          :id="receivable.id"
          :receivableRef="receivable.reference"
          :bank="receivable.bank"
          :currency="
            receivable.currency == 'NGN'
              ? '₦'
              : receivable.currency == 'USD'
              ? '$'
              : receivable.currency == 'EUR'
              ? '€'
              : receivable.currency == 'GBP'
              ? '£'
              : receivable.currency == null
              ? '₦'
              : receivable.currency
          "
          :customer="receivable.customer.email"
          :tag="receivable.tag"
          :date="receivable.created_at | date"
          :amount="receivable.total | currency(currencyformat)"
          :status="receivable.status"
          @openDetails="openDetails"
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-center align-center pt-6" v-else>
      <span class="text--primary text-h5 font-monospace"
        >No Data available</span
      >
    </div>
    <!-- pagination component -->
    <div
      class="pagination__container px-8"
      v-if="filteredReceivables && filteredReceivables.length > 0"
    >
      <BasePagination
        :current-page="currentPage"
        :visible-pages="visiblePageValue"
        :total-pages="totalPages"
        @page-changed="handlePageChange"
      />
    </div>
    <!-- right sided vuetify navigation drawer -->
    <div>
      <v-navigation-drawer
        width="360px"
        temporary
        right
        v-model="detailsDrawer"
        :hide-overlay="true"
        app
        style="box-shadow: 0px 4px 16px rgba(192, 196, 202, 0.15)"
      >
        <div
          class="d-flex justify-center align-center"
          style="height: 100vh; width: 100%"
          v-if="loadingDetails"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <ReceivableDetails
          @close="detailsDrawer = false"
          :receivableId="recId"
          v-else
        />
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import ReceivableTable from "./ReceivableTable.vue";
import ReceivablesTableCard from "./ReceivablesTableCard.vue";
import ReceivableDetails from "./receivableDetails.vue";
import BasePagination from "@/components/BasePagination.vue";
import pagination from "@/mixins/pagination.js";
import { mapGetters } from "vuex";
export default {
  components: {
    ReceivableTable,
    ReceivablesTableCard,
    ReceivableDetails,
    BasePagination,
  },
  data() {
    return {
      searchQuery: "",
      itemsPerPage: 10,
      autoPay: false,

      dragging: false,
      dialog: false,
      currencyformat: {
        symbol: "",
        thousandsSeparator: ",",
        fractionCount: "",
        fractionSeparator: "",
        symbolPosition: "",
        symbolSpacing: "",
        avoidEmptyDecimals: undefined,
      },
      detailsDrawer: false,
      isLoading: false,
      loadingDetails: false,
      recId: "",
    };
  },
  mixins: [pagination],
  methods: {
    closeModal() {
      this.dialog = false;
      // this.workflowName = e;
      // console.log(e);
    },
    onDroppedFiles($event) {
      this.dragging = false;
      let files = [...$event.dataTransfer.items]
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile());

      console.table(files);
    },
    setSearchText(value) {
      this.searchQuery = value;
    },
    //methods for pagination page change
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },

    // open details drawer
    async openDetails(receivableID) {
      this.detailsDrawer = true;
      this.loadingDetails = true;
      await this.$store.dispatch("receivables/getReceivable", receivableID);
      this.recId = receivableID;
      this.loadingDetails = false;
    },
  },
  computed: {
    //vuex getters
    ...mapGetters({
      paidReceivables: "receivables/getAllPaidReceivables",
      organizationToken: "organizations/OrganToken",
      singleReceivable: "receivables/getReceivables",
    }),

    filteredReceivables() {
      if (this.searchQuery) {
        return this.paidReceivables.filter((receivable) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => {
              return (
                receivable.reference.toLowerCase().includes(v) ||
                receivable.customer.email.toLowerCase().includes(v) ||
                receivable.bank.toLowerCase().includes(v) ||
                receivable.tag.toLowerCase().includes(v) ||
                receivable.status.toLowerCase().includes(v) ||
                receivable.currency.toLowerCase().includes(v) ||
                receivable.created_at.toLowerCase().includes(v)
              );
            });
        });
      } else return this.paidReceivables;
    },
    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredReceivables.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (
        this.filteredReceivables &&
        this.filteredReceivables.length > this.itemsPerPage
      ) {
        let arraySubset = this.filteredReceivables?.slice(startIndex, endIndex);
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      } else {
        let arraySubset = this.filteredReceivables?.slice();
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      }
    },
    totalPages() {
      return Math.ceil(this.filteredReceivables.length / this.itemsPerPage);
    },
  },
};
</script>

<style scoped>
.switch-card {
  height: 53px;
  background: rgba(127, 145, 155, 0.052607);
}

.switch {
  width: 15.28px;
  height: 15.28px;
}

.export-btn {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;

  color: #19283d;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 6px;
}
</style>
