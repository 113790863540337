<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="pl-10 pr-8 py-6"
        style="cursor: pointer; transition: all 0.3s ease"
        :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''"
      >
        <v-layout row wrap class="align-center">
          <v-flex md1>
            <div class="d-flex justify-center align-center">
              <span class="mb-0 row__text">{{ index }}</span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="mb-0 row__text text-truncate"
                    >{{ customer }}</span
                  >
                </template>
                <span>{{ customer }}</span>
              </v-tooltip>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex justify-center align-center">
              <span class="mb-0 row__text text-center">{{ currency }}</span>
            </div>
          </v-flex>

          <v-flex md2>
            <div class="d-flex align-center">
              <span class="mb-0 row__text">{{ amount }}</span>
            </div>
          </v-flex>
          <!-- <v-flex md1>
            <div class="d-flex align-center">
              <span class="mb-0 row__text">{{ bank }}</span>
            </div>
          </v-flex> -->
          <v-flex md2>
            <div class="d-flex align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="mb-0 row__text text-truncate"
                    style="max-width: 120px"
                    >{{ receivableRef }}</span
                  >
                </template>
                <span>{{ receivableRef }}</span>
              </v-tooltip>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center">
              <v-icon small :color="stausIcon"> mdi-circle-medium </v-icon>
              <span class="row__text">{{ status }}</span>
            </div>
          </v-flex>
          <!-- <v-flex md2>
            <div class="d-flex align-center pl-3">
              <span class="mb-0 row__text">{{ tag }}</span>
            </div>
          </v-flex> -->
          <v-flex md2>
            <div class="d-flex justify-center align-center">
              <span class="mb-0 row__text">{{ date }}</span>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center">
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="$emit('openDetails', id)"
                  :style="{
                    'background-color': hover ? '#2bd5ae' : '',
                    border: hover ? 'none' : '',
                  }"
                  exact-path
                  depressed
                  outlined
                  dark
                  small
                  color="#2BD5AE"
                  class="text-capitalize px-1 my-1 mr-1 rounded"
                  style="color: var(--v-primary-base)"
                >
                  Details
                </v-btn>
              </v-hover>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
    },
    id: {
      type: Number,
    },
    customer: {
      type: String,
    },
    amount: {
      type: String,
    },
    bank: {
      type: String,
    },
    receivableRef: {
      type: String,
    },
    tag: {
      type: String,
    },
    date: {
      type: String,
    },
    status: {
      type: String,
    },
    iconColor: {
      type: String,
    },
    currency: {
      type: String,
    },
  },
  data() {
    return {
      //
    };
  },
  computed: {
    stausIcon() {
      if (this.status === "unpaid") {
        return "#FF6A6A";
      } else return "#2BD5AE";
    },
  },
};
</script>

<style scoped>
.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}

.row__text {
  font-family: "inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #596a73;
}
</style>
